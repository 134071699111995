@import './components/order-map/_order-map.theme';
@import './components/order-item-manager/_order-item-manager.theme';
@import './components/order-incident-form/_order-incident-form.theme';
@import './components/order-incident-overlay/_order-incident-overlay.theme';

@mixin x-orders-theme($theme, $typography) {
  $warn: map-get($theme, warn);

  @include x-order-map-theme($theme, $typography);
  @include x-order-item-manager-theme($theme, $typography);
  @include x-order-incident-form-theme($theme, $typography);
  @include x-order-incident-overlay($theme, $typography);

  .color-warn {
    color: mat.get-color-from-palette($warn, 400);
  }

  x-order-info-panel {
    .stock-unit-table {
      tbody {
        tr {
          &.warning {
            color: var(--x-warn-500);
          }
          th {
          }
          td {
          }
          &.header {
            th,
            td {
              padding: 1rem 0 0.3rem 0;
            }
            th {
              a {
                display: flex;
                flex-flow: row nowrap;
                gap: 0.5rem;
                align-items: center;
              }
            }
            td {
              padding: 0.5rem 0 0.3rem 0;
              white-space: nowrap;
            }
            &:first-child {
              th,
              td {
                padding-top: 0;
              }
            }
            &:not(:first-child) {
              th,
              td {
                border-top: 1px solid var(--mat-menu-divider-color);
              }
            }
          }
          &:not(.header) {
            th {
              font-weight: normal;
              padding: 0 0 0.3rem 2rem;
            }
          }
          td.spacer {
            padding-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
