@use '@angular/material' as mat;
@mixin xd-expand-item-theme($theme, $typography) {
  $foreground: map-get($theme, 'foreground');
  $warn: map-get($theme, warn);

  .xd-expand-item {
    overflow: hidden;

    &:hover,
    &.open {
      background-color: white;
    }

    &.open {
      & > .expand-item-toggle {
        padding-top: 8px;
        padding-bottom: 8px;
        & > .expand-item-toggle-chevron {
          transform: rotate(180deg);
        }
      }
    }

    &.can-toggle {
      .expand-item-toggle {
        cursor: pointer;
      }
    }

    .expand-item {
      &-toggle {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        border: none;
        background-color: transparent;
        text-align: left;
        transition: padding 150ms ease;

        &-icon {
          flex: 0 0 48px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          padding: 12px 0;
          line-height: 20px;
        }

        &-body {
          display: flex;
          flex-flow: column nowrap;
          padding: 12px 0;
          flex: 1 1 auto;
          overflow: hidden;

          [xdLine] {
            display: block;
            padding: 0;
            margin: 0;
            @include mat.typography-level($typography, 'body-2');
          }

          [xdHeadline] {
            display: block;
            padding: 0;
            margin: 0;
            @include mat.typography-level($typography, 'body-1');
            line-height: 20px;
          }
        }

        &-description {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;
          position: relative;
          @include mat.typography-level($typography, 'body-2');
          color: mat.get-color-from-palette($foreground, 'secondary-text');
          font-weight: 500;
        }

        &-chevron {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 40px;
          transition: transform 150ms;
        }
      }

      &-content {
        overflow: hidden;
        &-inner {
          padding: 12px 16px;
        }
      }
      &-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 12px 16px;
        gap: 6px;
      }
    }
    &.warning {
      background-color: mat.get-color-from-palette($warn, 50);
      color: mat.get-color-from-palette($warn, 500);

      [xdHeadline],
      .list-item-icon {
        color: mat.get-color-from-palette($warn, 400);
      }
    }
  }
}
