@import './components/address-index-display/address-index-display.theme';
@import './components/address-pill/address-pill.theme';
@import './components/user-pill/user-pill.theme';
@import './components/bulk-order-pill/bulk-order-pill.theme';
@import './components/order-pill/order-pill.theme';
@import './components/order-payment-pill/order-payment-pill.theme';
@import './components/order-tag-pill/order-tag-pill.theme';
@import './components/channel-pill/channel-pill.theme';
@import './components/shipment-pill/shipment-pill.theme';
@import './components/subscriber-pill/subscriber-pill.theme';
@import './components/payment-pill/payment-pill.theme';
@import './components/user-payment-method-pill/user-payment-method-pill.theme';
@import './components/bulk-order-entry-pill/bulk-order-entry-pill.theme';
@import './components/subscriber-table/subscriber-table.theme';
@import './components/member-pill/member-pill.theme';
@import './components/subscriber-tag-pill/subscriber-tag-pill.theme';
@import './components/notification-pill/notification-pill.theme';

@mixin x-address-theme($theme, $typography) {
  @include x-address-index-display-theme($theme, $typography);
  @include x-address-pill-theme($theme, $typography);
  @include x-user-pill-theme($theme, $typography);
  @include x-order-pill-theme($theme, $typography);
  @include x-bulk-order-pill-theme($theme, $typography);
  @include x-order-payment-pill-theme($theme, $typography);
  @include x-order-tag-pill-theme($theme, $typography);
  @include x-channel-pill-theme($theme, $typography);
  @include x-shipment-pill-theme($theme, $typography);
  @include x-subscriber-pill-theme($theme, $typography);
  @include x-payment-pill-theme($theme, $typography);
  @include x-user-payment-method-pill-theme($theme, $typography);
  @include x-bulk-order-entry-pill-theme($theme, $typography);
  @include x-subscriber-table-theme($theme, $typography);
  @include x-member-pill-theme($theme, $typography);
  @include x-subscriber-tag-pill-theme($theme, $typography);
  @include x-notification-pill-theme($theme, $typography);

  .x-address-form {
    position: relative;

    font-size: 13px;
    font-weight: bold;

    .address-input-map-panel {
      background-color: white;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      overflow: hidden;
      z-index: 1;
      padding: 0;
      button {
        position: absolute;
        right: 8px;
        bottom: 8px;
        z-index: 2;
      }
    }

    .address-input-header {
      color: #333;
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  .x-address-input-overlay {
    border-radius: 4px;
    background-color: white;
    border-color: grey;
    @include mat.elevation(3);
    position: relative;
    padding: 16px;
    overflow: auto;
  }
}
