@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-order-payment-pill-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  .x-order-payment-pill {
    &.state-CART {
      background-color: mat.get-color-from-palette(map.get($colors, 'blue-gray'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'blue-gray'), '500-contrast');
    }
    &.state-AWAITING_PAYMENT {
      background-color: mat.get-color-from-palette(map.get($colors, 'yellow'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'yellow'), '500-contrast');
    }
    &.state-PARTIALLY_PAID {
      background-color: mat.get-color-from-palette(map.get($colors, 'light-green'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'light-green'), '500-contrast');
    }
    &.state-PAID {
      background-color: mat.get-color-from-palette(map.get($colors, 'green'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'green'), '600-contrast');
    }
    &.state-PARTIALLY_REFUNDED {
      background-color: mat.get-color-from-palette(map.get($colors, 'yellow'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'yellow'), '500-contrast');
    }
    &.state-REFUNDED {
      background-color: mat.get-color-from-palette(map.get($colors, 'amber'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'amber'), '500-contrast');
    }
    &.state-CANCELLED {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }
    &.state-SKIPPED {
      background-color: mat.get-color-from-palette(map.get($colors, 'amber'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'amber'), '500-contrast');
    }
  }
}
