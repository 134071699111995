@use '@angular/material' as mat;

@mixin x-data-table-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  .x-data-table {
    overflow: hidden;

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      padding: 6px 8px 6px 8px;
    }

    .selection-overlay {
      background-color: mat.get-color-from-palette($accent, 500);
      color: mat.get-color-from-palette($accent, 500-contrast);
      padding: 12px 16px;

      .selection-overlay-checkbox {
        .mat-checkbox-inner-container {
          margin-right: 18px;
        }
      }

      .selection-overlay-drag-handle {
        cursor: grab;
      }

      .selection-overlay-actions {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        gap: 12px;
      }
    }

    .table-container {
      overflow: auto;
      height: 100%;
    }

    tbody {
      vertical-align: middle;
    }

    th.mat-header-cell {
      padding-right: 6px;
    }

    td.mat-cell {
      padding: 2px 6px 2px 0;
    }

    .cdk-column-_action,
    .cdk-column-_action:last-of-type {
      padding-left: 6px;
      padding-right: 6px;
      border-left: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }

    .mat-cell.cdk-column-_select {
      width: 60px;
    }
    .mat-mdc-cell.cdk-column-_drag {
      width: 20px;
      text-align: center;
      .drag-handle {
        cursor: grab;
      }
    }

    &.x-reorderable {
      .cdk-column-_reorderDropZone {
        position: absolute !important;
        display: block;
        width: 100%;
        height: 0;
        bottom: 0px;
        top: unset !important;
        z-index: 1001 !important;
        padding: 0 !important;
        opacity: 0;
        transition: transform 32ms;
      }

      .cdk-column-_reorderDropZone.x-dropopen {
        &,
        &.mat-mdc-header-cell {
          height: 70px;
          transform: translateY(35px);
        }
        &.x-dragover {
          height: 100px;
        }
      }
      tr.x-dragging {
        opacity: 0.7;
        filter: grayscale(1);
      }
      tr.x-data-row,
      tr.x-data-header-row {
        &:has(.cdk-column-_reorderDropZone.x-dragover.x-dropopen) {
          td,
          th {
            &:not(.cdk-column-_reorderDropZone) {
              border-bottom-width: 100px;
              border-bottom-color: #f0f0f0;
            }
            &.cdk-column-_reorderDropZone {
              height: 170px;
            }
          }
        }
      }
    }

    .selection-box {
      cursor: pointer;
      position: relative;
      width: 40px;
      height: 40px;

      .mat-mdc-progress-spinner {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
      }
      .mat-mdc-checkbox {
        .mat-mdc-checkbox-layout {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .mat-mdc-checkbox-inner-container {
        }
      }
    }

    th,
    td {
      white-space: nowrap;
    }

    tr.x-data-header-row,
    tr.x-data-row {
      position: relative;
    }

    tr.x-data-row {
      &:hover {
        background-color: mat.get-color-from-palette($primary, 50);
      }
    }
    tr.x-data-row.x-row-disabled {
      opacity: 0.5;
    }
    tr.x-data-row.x-row-active {
      background-color: mat.get-color-from-palette($primary, 100);
      &:hover {
        background-color: mat.get-color-from-palette($primary, 100);
      }
    }
  }
  .x-data-action-base {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    height: 32px;

    border: none;
    cursor: pointer;

    border-bottom: 1px solid mat.get-color-from-palette($foreground, 'divider');

    @include mat.typography-level($typography, 'body-2');

    &.color-default {
      background-color: white;
      color: mat.get-color-from-palette($foreground, 'text');
    }

    &.color-primary {
      background-color: mat.get-color-from-palette($primary, 500);
      color: mat.get-color-from-palette($primary, '500-contrast');
    }

    &.color-accent {
      background-color: mat.get-color-from-palette($accent, 500);
      color: mat.get-color-from-palette($accent, '500-contrast');
    }

    &.color-warn {
      background-color: mat.get-color-from-palette($warn, 500);
      color: mat.get-color-from-palette($warn, '500-contrast');
    }

    &:disabled {
      opacity: 0.5;
      background-color: mat.get-color-from-palette($foreground, 'disabled-button');
      color: mat.get-color-from-palette($foreground, 'disabled-text');
      cursor: default;
    }
  }

  .x-primary-row-actions {
    display: flex;
    align-items: center;
    gap: 6px;

    .x-data-action-base {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid mat.get-color-from-palette($foreground, 'divider');
      .action-label {
        display: none;
      }
    }
  }
  .x-row-action-overflow-overlay-backdrop {
  }
  .x-row-action-overflow-overlay-panel {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    background-color: white;
    border: 1px solid mat.get-color-from-palette($foreground, 'divider');
    @include mat.elevation(2);

    border-radius: 2px;
    overflow: hidden;
    .x-data-action-base {
      height: 38px;
      padding: 0 12px;
      justify-content: flex-start;
      gap: 4px;
      &:last-child {
        border: none;
      }
      &:not(:disabled):hover {
        opacity: 0.7;
      }
    }
  }
}
