@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-payment-pill-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  .x-payment-pill {
    &.state-COMPLETED {
      background-color: mat.get-color-from-palette(map.get($colors, 'green'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'green'), '600-contrast');
    }
    &.state-PARTIALLY_REFUNDED {
      background-color: mat.get-color-from-palette(map.get($colors, 'yellow'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'yellow'), '500-contrast');
    }
    &.state-REFUNDED {
      background-color: mat.get-color-from-palette(map.get($colors, 'amber'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'amber'), '500-contrast');
    }
    &.state-CANCELLED {
      background-color: mat.get-color-from-palette(map.get($colors, 'yellow'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'yellow'), '500-contrast');
    }
    &.state-FAILED {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }
  }
}
